import UrlHandlerApiService from "./url-handler.service";
import Vue from "vue";

const CountryService = {
  URL: "",
  countries: [],

  async init() {
    this.URL = await UrlHandlerApiService.getBaseUrl();
  },

  ID_COUNTRY_KEY: "country_id",
  API_COUNTRY: "api/main-feed/country",

  /**
   * Send the GET HTTP request
   * @returns {*}
   */
  async getCountriesApi() {
    try {
      return Vue.axios.get(this.URL + this.API_COUNTRY, {
        params: { pageSize: 200 }
      });
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] CountryService ${error}`);
    }
  },

  /**
   * GET cached country list
   * @returns {*}
   */
  async getCountries() {
    if (this.countries?.length) {
      return this.countries;
    }

    const response = await this.getCountriesApi();
    this.countries = response?.data || [];

    return this.countries;
  },

  /**
   * GET Active Country ID
   * @returns {number}
   */
  getActiveCountry() {
    const countryId = window.localStorage.getItem(this.ID_COUNTRY_KEY);

    if (isNaN(countryId)) {
      return countryId;
    }

    return parseInt(countryId);
  },

  /**
   * SAVE Active Country ID
   * @returns {void}
   */
  saveActiveCountry(countryId) {
    window.localStorage.setItem(this.ID_COUNTRY_KEY, countryId);
  },
  /**
   * DESTROY Active Country ID
   * @returns {void}
   */
  destroyActiveCountry() {
    window.localStorage.removeItem(this.ID_COUNTRY_KEY);
  }
};

export default CountryService;
